.home_nav {
    /* border: 1px solid; */
    background: #2f77bb;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #2f77bb;
}

.navb_col {
    color: #deb31d;
    font-weight: 500;
}

.navb_col:hover {
    color: #2f77bb;
}

.nav-link {
    color: #deb31d;
}

/* ------------Navbar Complete--------------------- */

.scrollbtn {
    background-color: #2f77bb;
    border: 0;
    border-radius: 0;
}

.scrollbtn:hover {
    background-color: #deb31d;
    border: 0;
    border-radius: 0;
}

.zoom {
    transition: transform .4s;
}

.zoom:hover {
    transform: scale(1.1);
}

.underline {
    text-decoration: none;
}

.colo {
    border: white;
}

.prana_name {
    color: #E32428;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif Sans FB;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
}

.carousel-inner img {
    margin: auto;
}

.prana_border {
    border: 0;
    border-radius: 0;
}