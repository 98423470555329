.home_nav {
    /* border: 1px solid; */
    background: #2f77bb;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #2f77bb;
}

.navb_col {
    color: #deb31d;
    font-weight: 500;
}

.navb_col:hover {
    color: #2f77bb;
}

.nav-link {
    color: #deb31d;
}

/* ------------Navbar Complete--------------------- */

.scrollbtn {
    background-color: #2f77bb;
    border: 0;
    border-radius: 0;
}

.scrollbtn:hover {
    background-color: #deb31d;
    border: 0;
    border-radius: 0;
}

.zoom {
    transition: transform .4s;
}

.zoom:hover {
    transform: scale(1.1);
}

.underline {
    text-decoration: none;
}

.colo {
    border: white;
}

@media screen and (min-width:480px) {
    .map {
        height: 500px;
        width: 735px;
    }
}

.contact_textcol {
    font-size: 24px;
    font-weight: 500;
    color: #deb31d;
}

.storecol {
    color: #deb31d;
    text-decoration: none;
}

.whatsappcolor {
    color: white;
    background-color: mediumspringgreen;
    border-radius: 22px;
}

.call {
    height: 2.2em;
    width: 2.2em;
    color: white;
    background-color: navy;
    border-radius: 22px;
}

.icon-bar {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.icon-bar a {
    display: block;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    /* background-color: black; */
}