.home_nav {
    /* border: 1px solid; */
    background: #2f77bb;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #2f77bb;
}

.navb_col {
    color: #deb31d;
    font-weight: 500;
}

.navb_col:hover {
    color: #2f77bb;
}

.nav-link {
    color: #deb31d;
}

/* ------------Navbar Complete--------------------- */

.scrollbtn {
    background-color: #2f77bb;
    border: 0;
    border-radius: 0;
}

.scrollbtn:hover {
    background-color: #deb31d;
    border: 0;
    border-radius: 0;
}

.zoom {
    transition: transform .4s;
}

.zoom:hover {
    transform: scale(1.1);
}

.underline {
    text-decoration: none;
}

.colo {
    border: white;
}

.home_slider {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width:480px) {
    .desktopview {
        display: none;
    }
}

@media screen and (min-width:480px) {
    .mobileview {
        display: none;
    }
}

.home_product {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 17.4em;
    width: 17.4em;
    background-color: #2f77bb;
}

.home_sizemat {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 21em;
    width: 21em;
    background-color: #2f77bb;
}

.home_brand {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 12.4em;
    width: 22em;
}

.home_review {
    border-radius: 0;
}

.home_textcol {
    font-size: 22px;
    font-weight: 500;
    color: #2f77bb;
}

.home_matcol {
    color: #deb31d;
}

.home_sofacol {
    color: white;
}

.home_pillowcol {
    color: #deb31d;
}

.home_bedsheetcol {
    color: white;
}

.home_matprocol {
    color: #deb31d;
}

.home_matbysize {
    font-size: 22px;
    font-weight: 500;
    color: #deb31d;
}

.home_singlecol {
    color: white;
}

.home_doublecol {
    color: #deb31d;
}

.home_kingcol {
    color: white;
}

.home_queencol {
    color: #deb31d;
}

.home_matbybrand {
    font-size: 22px;
    font-weight: 500;
    color: #2f77bb;
}

.home_review {
    font-size: 22px;
    font-weight: 500;
    color: #deb31d;
}

/* ------------Footer---------------- */

.footerbackcolor {
    background-color: #2f77bb;
}

.footbottomcolor {
    background-color: #deb31d;
}

.foottextcolor {
    color: #deb31d;
}

/* .foottextcolor2 {
    color: #deb31d;
} */

.bgco {
    background-color: #2f77bb;
}

.bgco:hover {
    background-color: #2f77bb;
}

a {
    color: white;
}

.whatsappcolor {
    color: white;
    background-color: mediumspringgreen;
    border-radius: 22px;
}

.call {
    height: 2.2em;
    width: 2.2em;
    color: white;
    background-color: navy;
    border-radius: 22px;
}

.icon-bar {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}

.icon-bar a {
    display: block;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease;
    color: white;
    font-size: 20px;
    /* background-color: black; */
}